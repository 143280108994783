import { Alert, Checkbox, Form, Spin } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationTypeSectionTitle } from "./ApplicationTypeSectionTitle";
import { useApplicationType } from "./hooks/useApplicationType";
import { useReportFields } from "./hooks/useReportFields";

interface Props {
  currentApplicationType: number;
  getFieldValue: WrappedFormUtils["getFieldValue"];
  setFieldsValue: WrappedFormUtils["setFieldsValue"];
}
export function ApplicationTypeFieldsForm({
  currentApplicationType,
  getFieldValue,
  setFieldsValue,
}: Readonly<Props>) {
  const applicationType = useApplicationType(currentApplicationType);

  const [options, loading, error] = useReportFields(currentApplicationType);

  const allValues = useMemo(
    () => options.map((option) => option.value),
    [options]
  );

  const retriveInitialValueFrom = (fieldName: string) => {
    const value = getFieldValue(fieldName);
    if (value === undefined) return allValues; // All values
    else return value;
  };

  const onChange = useCallback(
    (values: string[]) => {
      const value = values.length === allValues.length ? undefined : values;
      setFieldsValue({ [`fields.at${currentApplicationType}`]: value });
    },
    [allValues, currentApplicationType, setFieldsValue]
  );

  return (
    <div
      className="application-type-form-animation"
      key={currentApplicationType}
    >
      <ApplicationTypeSectionTitle applicationType={applicationType} />
      {error && (
        <Alert
          type={"error"}
          message={
            <FormattedMessage
              id={"error-loading-fields-for-application-type"}
              defaultMessage={
                "There was an error loading the field list for the selected application type"
              }
            />
          }
          style={{ maxWidth: "100%" }}
        />
      )}
      {!error && loading && <Spin size="small" />}
      {!error && !loading && (
        <Form.Item
          label={
            <FormattedMessage
              id="Select fields to display"
              defaultMessage="Select fields to display"
            />
          }
          className="customize-modal__label"
        >
          <Checkbox.Group
            onChange={onChange}
            defaultValue={retriveInitialValueFrom(
              `fields.at${currentApplicationType}`
            )}
            options={options}
            className={"vertical"}
          />
        </Form.Item>
      )}
    </div>
  );
}
